/* eslint-disable */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { LOGO } from 'assets/images';
import './layout.css';
import './SideBar.css';
import { useTranslation } from 'react-i18next';
import DataItemsAdmin from 'statics/DataItemsAdmin';

function Sidebar({ isMenuOpened }) {
  const { pathname } = window.location;
  const { t } = useTranslation();

  const [nestedIndex, setNestedIndex] = useState(0);
  const [logoImage, setLogoImage] = useState(LOGO);
  // const { userData } = useSelector(state => state.user);
  const history = useHistory();
  const [sidebarAccess, setSidebarAccess] = useState([]);

  const { Theme } = useSelector(state => state.auth);
  useEffect(() => {
    const data = DataItemsAdmin(t);
    setSidebarAccess(data);
  }, [t]);

  return (
    <nav className={isMenuOpened ? '' : 'nav_show'}>
      <div className={`d-flex mt-2 ${isMenuOpened ? 'justify-content-start ms-1' : 'lh70'}`}>
        <div className={`${isMenuOpened ? 'pl10' : ''}`}>
          {/* platform logo */}
          <NavLink to="/" className="logo logo-dark text-decoration-none">
            {!isMenuOpened ? (
              <span className="logo-sm mt-2">
                <img src={logoImage} alt="sllabslogo-sm" height="47" width="200" className="px-4" />
              </span>
            ) : (
              <img src={LOGO} alt="sllabslogo-sm" height="70" width="80" className="" />
            )}

            {/* {!isMenuOpened ? (
              <span className="logo-lg">
                <img src={logoImage} className="platform_logo" alt="sllabslogo-lg" height="36" />
              </span>
            ) : (
              <img src={LOGO} alt="sllabslogo-sm" height="30" />
            )} */}
          </NavLink>
        </div>
      </div>
      {/* sidebar data */}
      <ul>
        {sidebarAccess?.map((item, index) => (
          <>
            <li
              title={item?.name}
              className={`sidebar-nav-item ${
                isMenuOpened && pathname === item?.link ? 'nonActive' : 'activeitem'
              }`}
              onClick={() => {
                if (item?.nestedtab) {
                  if (nestedIndex === index) setNestedIndex(0);
                  else setNestedIndex(index);
                } else {
                  if (item.name === 'Logout') {
                    localStorage.clear();
                    window.location.reload();
                    return;
                  }
                  history.push(item.link);
                  setNestedIndex(0);
                }
                // openLeftMenuCallBack();
              }}
              key={item.name}
            >
              {item?.nestedtab ? (
                <a>
                  {item.logo}
                  {item.name}
                  {nestedIndex === index ? (
                    <i className="uil-angle-up" />
                  ) : (
                    <i className="uil-angle-down" />
                  )}
                </a>
              ) : (
                <Link
                  to={item.link}
                  onClick={() => {
                    // openLeftMenuCallBack();
                  }}
                  id={pathname === item.link || pathname.includes(item?.tab) ? 'closemenu_svg' : ''}
                  className={
                    pathname === item.link
                      ? 'active_nav_tab'
                      : pathname.includes(item?.tab)
                      ? 'active_nav_tab'
                      : 'inactive_nav_tab'
                  }
                >
                  {item.logo}
                  <div className="sidebar-name">{item.name}</div>
                </Link>
              )}
            </li>
            {nestedIndex && nestedIndex === index
              ? sidebarAccess[nestedIndex]?.nestedtab?.map(nest => (
                  <li
                    className={
                      isMenuOpened
                        ? pathname === nest.link
                          ? 'sidebar-nav-item nonActive nested-item'
                          : 'sidebar-nav-item nonActive nested-item'
                        : pathname === nest.link
                        ? 'activeitem nested-item'
                        : 'nested-item'
                    }
                    onClick={() => {
                      history.push(nest.link);
                      // openLeftMenuCallBack();
                    }}
                    key={`nested${nest.name}`}
                  >
                    <Link
                      to={nest.link}
                      onClick={() => {
                        // openLeftMenuCallBack();
                      }}
                      className={pathname === nest.link ? 'color-rgb' : 'color-black'}
                    >
                      <i className={nest.logo} />
                      {nest.name}
                    </Link>
                  </li>
                ))
              : null}
          </>
        ))}
      </ul>
    </nav>
  );
}

export default Sidebar;
