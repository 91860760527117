// LOGIN
export const LOGIN_SAGA = 'LOGIN_SAGA';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

// FORGOT AUTHENTICATOR
export const FORGOT_AUTHENTICATOR_SAGA = 'FORGOT_AUTHENTICATOR_SAGA';
export const FORGOT_AUTHENTICATOR_START = 'FORGOT_AUTHENTICATOR_START';
export const FORGOT_AUTHENTICATOR_SUCCESS = 'FORGOT_AUTHENTICATOR_SUCCESS';
export const FORGOT_AUTHENTICATOR_FAIL = 'FORGOT_AUTHENTICATOR_FAIL';
export const RESET_FORGOT_AUTHENTICATOR = 'RESET_FORGOT_AUTHENTICATOR';

// language selected
export const LANGUAGE_CHOOSE_SAGA = 'LANGUAGE_CHOOSE_SAGA';
export const LANGUAGE_CHOOSE_SAGA_START = 'LANGUAGE_CHOOSE_SAGA_START';
export const LANGUAGE_CHOOSE_SAGA_SUCCESS = 'LANGUAGE_CHOOSE_SAGA_SUCCESS';
export const LANGUAGE_CHOOSE_SAGA_FAIL = 'RESET_AUTHENTICATOR_FAIL';
